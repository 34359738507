<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  created () {
    localStorage.setItem("access_token", this.getquery());

  },
  mounted () {
    localStorage.setItem("access_token", this.getquery());

    if (window.localStorage.getItem('cueTheme') == 'dattheme') {
      document.documentElement.setAttribute('theme', 'dattheme')
      document.querySelector('body').setAttribute('style', 'background:#181920 !important')

    } else {
      document.documentElement.setAttribute('theme', '')
      // document.documentElement.setAttribute('theme', 'dattheme')
      // document.querySelector('body').setAttribute('style', 'background:#181920 !important')
      // document.querySelector('body').setAttribute('style', 'background:#ffffff !important')

    }

  },
  methods: {
    getquery () {
      if (window.location.hash.indexOf("?") != -1) {
        let data = window.location.hash.split("?")[1];

        let data1 = data.split("&");

        if (data1[0]) {
          var index = data1[0].lastIndexOf("=");
          var res = data1[0].substring(index + 1, data1[0].length);
        }

        if (data1[1]) {
          localStorage.setItem("opentype", data1[1].split("=")[1]);
        }
        if (data1[2]) {
          localStorage.setItem("cueTheme", data1[2].split("=")[1]);
        }
        if (data1[3]) {
          localStorage.setItem('urlType', data1[3].split('=')[1])
        }

        if (res != null) {
          return decodeURIComponent(res);
        }
        return null;
      }
    },
  },
};
</script>

<style >
@import "assets/css/base.css";


ul,
li {
  list-style: none;
}
 .u-navbar-inner {
		height: 100% !important;
	}
/* img {
  image-rendering: -moz-crisp-edges !important;
  image-rendering: -o-crisp-edges !important;
  image-rendering: -webkit-optimize-contrast !important;
  image-rendering: crisp-edges !important;
  -ms-interpolation-mode: nearest-neighbor !important;
} */
::-webkit-scrollbar {
  /* 隐藏滚动条，但依旧具备可以滚动的功能 */

  display: none;
}

:root {
  --w-colortext: #ffffff !important;
  --w-colortext1: #666666 !important;
  --w-colortext2: #333333 !important;
  --td-colortext: #283D68 !important;
  --li-colortext: #000000 !important;

  --w-bgcolor: #ffffff !important;
  --w-bgcolor2: #eef7ff !important;

  --w-bordercolor: #eeeeee !important;
  --w-bordercolor1: #eeeeee !important;

}

:root[theme='dattheme'] {
  --w-colortext: #181920 !important;
  --w-colortext1: #ffffff !important;
  --w-colortext2: #ffffff !important;
  --td-colortext: #ffffff !important;
  --li-colortext: #ffffff !important;


  --w-bgcolor: #2c2f37 !important;
  --w-bgcolor2: #181920 !important;


  --w-bordercolor: #2c2f37 !important;
  --w-bordercolor1: #ffffff !important;


}
</style>
